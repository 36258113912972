import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = (props) => {

    const getIndicator = () => {
        const status = props.usageStatus || 'neutral';
        return <div className="indicator">
                    <span className={ `icon icon-${status}` }></span>
                </div>
    }
    
    return <div className="chart chart--doughnut">

            <header className="chart__legend">
                <div className="key">
                    
                    <div className="key__label">
                        <span className="pill">{ props.period }</span>
                        { getIndicator() }
                    </div>
                    
                </div>
            </header>

            <div className="chart__chart">
                <Doughnut 
                    data={ props.data }
                    options={ props.options }
                    datasetKeyProvider={ () => btoa(Math.random()).substring(0,12) }
                />
            </div>

            <footer className="chart__foot">

                <div className="reference-bar">
                    <div className="indicator">
                        <span className="label">{ props.dailyTargetRef }</span>
                    </div>
                </div>

            </footer>
        </div>
}

export default DoughnutChart;