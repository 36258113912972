import React from 'react';
import UseChart from './UseChart'
import CarbonFootprintChart from './Charts/CarbonFootprintChart'

const CarbonFootprint = (props) => {
    return <>
        <h2>{ props.title }</h2>
        <UseChart
            className="carbon-footprint"
            title={ props.subTitle }
            overview={ props.overview}>

            <CarbonFootprintChart 
                data={ props.data }
            />
        </UseChart>
    </>
}

export default CarbonFootprint;