import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = (props) => {

    const getIndicator = () => {
        const status = props.usageStatus || 'neutral';
        return <div className="indicator">
                    <span className={ `icon icon-${status}` }></span>
                    <span className="label">this month</span>
                </div>
    }

    const getIndicatorIconClass = (usage) =>
    {
        const status = usage === 'neutral' ? 'circle' : usage;
        return `icon icon-${status}`;
    }

    const getKeys = () => {
        return props.data.datasets.map( dataset => {
            if( dataset.label === 'dataset-target' ) return <span key="target-key" className="key__target">--- target</span>;
            if( dataset.label === 'dataset-last-year' ) return <span key="ref-key" className="key__ref">--- last year</span>;

            return null;
        });
    }

    return <div className="chart">

            <header className="chart__legend">
                <div className="key">
                    
                    <div className="key__label">
                        <span className="pill">{ props.period }</span>
                    </div>

                    <div className="key__values">
                        { getKeys() }
                    </div>
                </div>

                { ! props.hideIndicator && getIndicator() }

            </header>
            
            <div className="chart__chart">
                <Bar 
                    data={ props.data }
                    options={ props.options }
                />
            </div>
            
            <footer className="chart__foot">

                <div className="reference-bar">
                    { props.average && 
                        <div className="indicator">
                            <span className={ getIndicatorIconClass(props.usageStatus) }></span>
                            <span className="label">{ props.average }</span>
                        </div>
                    }

                    { props.total && 
                        <div className="indicator">
                            <span className={ getIndicatorIconClass(props.usageStatus) }></span>
                            <span className="label">{ props.total }</span>
                        </div>
                    }
                </div>

            </footer>

        </div>
}

export default BarChart;