import React from 'react';

const CarbonFootprintChart = (props) => {

    // get the highest usage value from all provided years
    let maxCO2Val = props.data.annual_data_sets.reduce(function(prev, current) {
        return (prev.tons_c02 > current.tons_c02) ? prev.tons_c02 : current.tons_c02
    });

    maxCO2Val = Math.round(maxCO2Val / 50) * 50;

    // lay out a track in increments of 50, with one extra so that we're never at 100%
    const numberOfSegments = Math.ceil(maxCO2Val / 50) + 1;
    
    const chartWidth = 1012;
    const trackUsableSpace = chartWidth - (chartWidth / numberOfSegments);

    const getUsageWidth = (usage) => {
        
        const pct = usage / maxCO2Val;

        console.log(usage, maxCO2Val, pct, chartWidth, trackUsableSpace);
        
        return `${ trackUsableSpace * pct}px`;
        // return (usage / (numberOfSegments * 50)) * 100;
    }

    const getSegmentWidth = () => {
        return `${ Math.ceil( chartWidth / numberOfSegments ) }px`
    }

    return props.data.annual_data_sets.map( (year, index) => {
        
        const trees = [];
        const tons = [];

        // add one for the 0 index, ie "0 trees"
        for(let i=0; i<=numberOfSegments; i++)
        {
            trees[i] = i * 10;
            tons[i] = i * 50;
        }

        trees.shift();
        tons.shift();

        return <div className="chart bar-chart-flex" key={ index }>
                <div className="chart__legend">
                    <div className="key">
                        <div className="key__label">
                            <div className="pill">{ year.year }</div>
                        </div>
                        <div className="key__values">
                            <div className="key__val">
                                CO<sub>2</sub>e offset
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="bar-chart__chart">
                
                    <div className="axis">
                        <div style={{minWidth: getSegmentWidth() }}><span>0 trees</span></div>
                        
                        { trees.map( step => {
                            return <div key={ `tree-${step}` } style={{minWidth: getSegmentWidth() }}><span>{ step }</span></div>    
                        })}

                    </div>

                    <div className="track">
                        <div className="bar bar--trees" style={ { maxWidth: `${ getUsageWidth(year.tons_c02) }` } }><span></span></div>
                    </div>

                    <div className="axis">
                        <div style={{minWidth: getSegmentWidth() }}><span>0 tons</span></div>

                        { tons.map( step => {
                            return <div key={ `ton-${step}` } style={{minWidth: getSegmentWidth() }}><span>{ step }</span></div>    
                        })}

                    </div>
                    
                </div>
            </div>
    });
}

export default CarbonFootprintChart;

