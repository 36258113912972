import React from 'react';
import UseChart from './UseChart'
import BarChart from './Charts/BarChart';
import ChartUtils from './Charts/chart-utils';

const EnergyUse = (props) => {

    // const data = ChartUtils.getBarChartDataObject(props.usage, props.target, props.lastYear, props.monthLabels);
    // hiding last year for the moment
    
    const usage = props.usage;
    const max = usage.reduce(function(a, b) {
        return Math.max( a, b);
    });

    const target = props.target;
    const lastYear = []; // Array(13).fill(300); //  props.lastYear;
    const extrapolatedUsage = props.extrapolatedUsage;

    const data = ChartUtils.getBarChartDataObject(usage, target, lastYear, extrapolatedUsage, props.monthLabels);
    const options = ChartUtils.getBarchartOptions('energy');

    options.scales.yAxes[0].ticks.maxTicksLimit = 8;
    options.scales.yAxes[0].ticks.suggestedMax = max;

    return <>
        <h2>{ props.title }</h2>
        <UseChart 
            className="energy"
            title={ props.subTitle }
            overview={ props.overview }
            color="green">
            
            <BarChart 
                period="Annual"
                average={ <span><em>{ props.monthlyAverage }</em> kWh monthly average</span> }
                total={ <span><em>{ props.annualTotal }</em> kWh annual total</span> }
                data={ data }
                options={ options }
                usageStatus={ props.usageStatus }
                
                extrapolatedValueForPeriod={ props.extrapolatedMonthlyTotal }
                targetValueForPeriod={ props.targetValueMonthly }
                />
        </UseChart>
    </>
}

export default EnergyUse;