import React from 'react';
import BarChart from './Charts/BarChart';
import DoughnutChart from './Charts/DoughnutChart';
import ChartUtils from './Charts/chart-utils';

const ServiceChart = (props) => {

    const barChartData = ChartUtils.getBarChartDataObject(
        props.data.usage.usage,
        props.data.target,
        [], // props.data.usage.lastYear,
        props.data.extrapolated_usage, // extrapolated data series
        props.data.usage.labels,
        'energy',
    );

    const targetLabelValue = ChartUtils.roundNum(parseFloat(props.data.today.target));
    const barChartOptions = ChartUtils.getBarchartOptions('energy', 'weekly');

    const doughnutChartData = {
        datasets: [
            ChartUtils.getDoughnutChartDataset(props.data.today.target, [
                '#cac9c2',
                // ChartUtils.getColor('service', 'static'),  // gray
                ChartUtils.getColor('service', 'static'),  // gray
                ChartUtils.getColor('service', 'inactive') // white
            ]),

            ChartUtils.getDoughnutChartDataset(props.data.today.current, [ 
                //ChartUtils.getColor('service', 'extrapolated'),        // green
                
                
                ChartUtils.getColor('service', 'active'),        // green
                '#d5e4c1',

                // '#cdd947',
                // '#8ca553',

                // ChartUtils.getColor('service', 'active'),  // alt green
                ChartUtils.getColor('service', 'inactive')       // white
            ])
        ]
    };

    const doughnutChartOptions = ChartUtils.getDoughnutChartoptions();

    const getUsageMessage = (status) =>
    {
        switch(status)
        {
            case 'low': return props.data.low_usage_msg;
            case 'high': return props.data.high_usage_msg;
            case 'neutral': return props.data.nominal_usage_msg;
        }
    }

    return <div className="columns__column">
                <div className="services-column">
                    <div className="services-column__overview">
                        <h3>{ props.data.title }</h3>
                        <p>{ getUsageMessage(props.data.usage_status) }</p>
                    </div>
                </div>

                <div className="services-column__chart">
                    <DoughnutChart 
                        period="Now"
                        data={ doughnutChartData }
                        options={ doughnutChartOptions }
                        usageStatus={ props.data.usage_status }
                        dailyTargetRef={ <span><em>{ targetLabelValue }</em> kWh daily target</span> }
                        />
                </div>

                <div className="services-column__chart">
                    <div className="gradient gradient--green">

                        <BarChart 
                            period="2 weeks"
                            average={ `${props.data.usage.average} kWh daily average` }
                            data={ barChartData }
                            usageStatus={ props.data.usage_status }
                            options={ barChartOptions }
                            hideLastYear={ true }
                            hideIndicator={ true }
                            color="green"
                            />
                    </div>
                </div>

            </div>
}

export default ServiceChart;