const ChartUtils = {

    colors: {
        energy: {
            static: '#ADB64D',
            active: '#CADB2A',
            current: '#8BA549',
        },

        service: {
            static: '#D3D3CB',   // gray  (target)
            active: '#8CC540',   // green (usage)
            extrapolated: '#CADB2A',   // green (usage)
            inactive: '#FFFFFF', // white (sliver)
            test: '#F0F0F0', // white (sliver)
        },

        water: {
            static: '#3FA9F5',
            active: '#00EAE9',
            current: '#4183a3'
        }
    },

    getColor( color, state )
    {
        if( this.colors[color]  && this.colors[color][state] )
        {
            return this.colors[color][state];
        }
        else
        {
            console.log(color, state)
            return '#fff';
        }
    },

    getBarchartOptions(type)
    {
        const tickCallback = type === 'water'
            ? (value, index, values) => { return value === 0 ? 'gal' : `${value} gal`; }
            : (value, index, values) => { return value === 0 ? 'kWh' : value; };

        const options = {
            tooltips: {
                enabled: false
            },
            legend: {
                display: false
            },
            borderSkipped: false,
            maintainAspectRatio: false,
            cornerRadius: 6,
            scales: {
                xAxes: [{
                    stacked: true,
                    scaleLabel: {
                        display: false,
                    },
                    gridLines: {
                        borderColor: '#666',
                        drawBorder: true,
                        drawOnChartArea: false,
                        lineWidth: 5,
                        zeroLineColor: 'transparent',
                        color: '#CCC',
                    },
                    ticks: {
                        fontColor: '#FFF',
                        fontSize: '16',
                        beginAtZero: true
                    }
                }],
                yAxes: [{
                    stacked: true,
                    scaleLabel: {
                        display: false,
                    },
                    gridLines: {
                        drawBorder: true, 
                        lineWidth: 0,
                        color: 'transparent',
                        zeroLineColor: 'transparent'
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 5,
                        beginAtZero: true,
                        step: 200,
                        fontColor: '#FFF',
                        fontSize: '16',
                        callback: tickCallback
                    }
                }]
            }
        };

        return options;
    },

    getBarChartDataObject(usage = [], target = [], lastYear = [], extrapolatedSeries = [], labels = [], color='energy', thickness=22)
    {
        const datasets = [];

        if( usage && usage.length )
        {
            const backgroundColors = Array(13).fill( this.getColor(color, 'static') );
            const currentPeriodIndex = this.getCurrentPeriodFromDataset(usage);
            backgroundColors[currentPeriodIndex] = this.getColor(color, 'active')

            datasets.push( this.getBarDataset(usage, backgroundColors, thickness, 'dataset-usage') );
        }

        if( extrapolatedSeries && extrapolatedSeries.length )
        {
            datasets.push( this.getBarDataset(extrapolatedSeries, this.getColor(color, 'current'), thickness, 'dataset-extrapolated') );
        }

        if( target && target.length )
        {
            datasets.push( this.getLineDataset(target, 'rgba(255,255,255, 0.6)', 'dataset-target') );
        }

        if( lastYear && lastYear.length )
        {
            datasets.push( this.getLineDataset(lastYear, '#4D4D4D', 'dataset-last-year') );
        }

        return {
            labels: labels,
            datasets: datasets
        };
    },

    getLineDataset(data, borderColor, label)
    {
        return {
            data: data,
            type: 'line',
            fill: false,
            borderDash: [10,5],
            borderColor: borderColor,
            pointBorderColor: 'transparent',
            lineTension: 0,
            pointRadius: 0,
            label: label,
        }
    },

    getBarDataset(data=[], backgroundColors=[], thickness=22, label)
    {
        return {
            data: data,
            backgroundColor: backgroundColors,
            barThickness: thickness,
            maxBarThickness: thickness,
            label: label,
        }
    },

    getDoughnutChartDataset(data=[], backgroundColors=[])
    {
        return {
            borderWidth: 45,
            borderColor: 'rgba(0,0,0,0)',
            data: data,
            backgroundColor: backgroundColors
        }
    },

    getDoughnutChartoptions()
    {
        return {
            tooltips: {
                enabled: false
            },
            backgroundColor: '#F0F0F0',
            circumference: Math.PI,
            rotation: Math.PI,
            maintainAspectRatio: false,
            responsive: true,
            cutoutPercentage: 60,
            legend: {
                display: false
            },
        }
    },

    // these data sets are padded to the right with 0, for months which have no data, ie
    // [120, 130, 156, 0, 0, 0]
    // so iterate backwards through the dataset to find the first non empty number, 
    // and that will be the current period (day/month)
    getCurrentPeriodFromDataset(dataset)
    {
        for( let i=dataset.length-1; i>=0; i--)
        {
            if( dataset[i] !== 0) return i;
        }
        return dataset.length;
    },

    roundNum(number)
    {
        if( number > 1 ) return Math.round( number )
        return Math.round(number * 100) / 100
    }
}

export default ChartUtils;