import React, { useEffect, useState } from 'react';

const Clock = () => {
    
    const [ currentDate, setCurrentDate ] = useState( '' );
    const [ currentTime, setCurrentTime ] = useState( '' );

    const tick = () => {
        setCurrentDate( getCurrentDate() );
        setCurrentTime( getCurrentTime() );
        //console.log('tick');
    };

    const getCurrentDate = () => {
        const currentDate = new Date();

        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const year = currentDate.getFullYear().toString().substr(-2);

        return `${month}/${day}/${year}`;
    }

    const getCurrentTime = () => {
        const currentDate = new Date();

        const hour = (currentDate.getHours() + 24) % 12 || 12;
        const minutes = `0${currentDate.getMinutes()}`.slice(-2); // produce a string like 01 or 012, and then return the last two characters
        const ampm = currentDate.getHours() >= 12 ? 'pm' : 'am';

        return `${hour}:${minutes}${ampm}`;
    }

    const getClock = () => {
        return <time className="clock">
            <div className="clock__date">{ currentDate }</div>
            <div className="clock__time">{ currentTime }</div>
        </time>
    }

    useEffect( () => {
        tick();
        
        const interval = 30 * 1000;
        let timer = setInterval( () => { tick() }, interval );

       return () => {
           clearInterval( timer );
       }
    }, []);

    return getClock();

}

export default Clock;