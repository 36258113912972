import React, { useEffect, useState } from 'react';
import Shell from './layout/Shell';
import Masthead from './layout/Masthead';

import { Chart } from 'react-chartjs-2';

import EnergyUse from './components/EnergyUse';
import WaterUse from './components/WaterUse';
import ServiceChartsWrapper from './components/ServiceChartsWrapper';
import ServiceChart from './components/ServiceChart';
import CarbonFootprint from './components/CarbonFootprint';

import addRoundedCorners from './helpers/ChartsjsHelpers_roundedCorners';

function Dashboard() {

    // data 
    const [energyData, setEnergyData] = useState({});
    const [plugLoadData, setPlugLoadData] = useState({});
    const [lightingData, setLightingData] = useState({});
    const [hvacData, setHvacData] = useState({});
    const [waterData, setWaterData] = useState({});
    const [carbonFootprintData, setCarbonFootprintData] = useState({});

    // app state
    const [loaded, setLoaded] = useState(false);

    const baseUrl = window.baseUrl || 'http://weberthompsondashboard.cm/';
    const url = window.ajaxUrl || 'http://weberthompsondashboard.cm/wp-admin/admin-ajax.php';
    const ajaxUrl = `${url}?action=ajax_get_json`;
    const pollUrl = `${baseUrl}api/poll.php`;
        
    let version = 0;

    const getData = () => {
        fetch(ajaxUrl)
            .then( response => response.json())
            .then( response => {

                if( response.energyData) setEnergyData(response.energyData);
                if( response.plugLoadData) setPlugLoadData(response.plugLoadData);
                if( response.lightingData) setLightingData(response.lightingData);
                if( response.hvacData) setHvacData(response.hvacData);
                if( response.waterData) setWaterData(response.waterData);
                if( response.carbonFootprintData) setCarbonFootprintData(response.carbonFootprintData);

                version = response.version;

                setLoaded(true);
            });
    }

    const bindToServerEvent = () => {
        const evtSource = new EventSource(pollUrl);
        
        evtSource.onmessage = (message) => {
            if( version !== message.data && version !== 0 )
            {
                getData();
            }
        }
    }

    useEffect( () => {
        getData();
        bindToServerEvent();
        // if( Chart ) addRoundedCorners( Chart );
    }, [] );

    const getCharts = () => {
        return !loaded 
            ? null
            : <>
                <EnergyUse 
                    title={ energyData.title }
                    subTitle={ energyData.sub_title }
                    overview={ energyData.overview }
                    // data
                    usage={ energyData.usage }
                    target={ energyData.target } 
                    usageStatus={ energyData.usage_status }

                    extrapolatedUsage={ energyData.extrapolated_usage } 
                    extrapolatedMonthlyTotal={ energyData.extrapolated_monthly_total } 

                    lastYear={ energyData.last_year } 
                    monthLabels={ energyData.month_labels } 
                    
                    // figures
                    currentUsageAnnualTotal={ energyData.annual_total }
                    currentUsageMonthlyAverage={ energyData.monthly_average }
                    annualTotal={ energyData.annual_total }
                    monthlyAverage={ energyData.monthly_average }

                    targetValueMonthly={ energyData.monthly_target_value }
                    targetValueDataSeries={ energyData.target }
                    />
                
                <hr />

                <ServiceChartsWrapper>
                    <ServiceChart data={plugLoadData} />
                    <ServiceChart data={lightingData} />
                    <ServiceChart data={hvacData} />
                </ServiceChartsWrapper>

                <WaterUse 
                    title={ waterData.title }
                    subTitle={ waterData.sub_title }
                    overview={ waterData.overview }

                    // data
                    usage={ waterData.usage }
                    target={ waterData.target } 
                    usageStatus={ waterData.usage_status }

                    extrapolatedUsage={ waterData.extrapolated_usage } 
                    extrapolatedMonthlyTotal={ waterData.extrapolated_monthly_total } 

                    lastYear={ waterData.last_year } 
                    monthLabels={ waterData.month_labels } 
                    
                    // figures
                    currentUsageAnnualTotal={ waterData.annual_total }
                    currentUsageMonthlyAverage={ waterData.monthly_average }
                    annualTotal={ waterData.annual_total }
                    monthlyAverage={ waterData.monthly_average }

                    targetValueMonthly={ waterData.monthly_target_value }
                    targetValueDataSeries={ waterData.target }
                    />
                
                <CarbonFootprint 
                    title={ carbonFootprintData.title}
                    subTitle={ carbonFootprintData.sub_title}
                    overview={ carbonFootprintData.overview}
                    data={ carbonFootprintData }
                    />
              </>
    }

    return  <Shell>
                <Masthead />
                <div className="main">
                    { getCharts() }
                </div>
            </Shell>
}

export default Dashboard;
