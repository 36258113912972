import React from 'react';
import UseChart from './UseChart'
import BarChart from './Charts/BarChart';
import ChartUtils from './Charts/chart-utils';

const WaterUse = (props) => {

    const options = ChartUtils.getBarchartOptions('water');
    // const data = ChartUtils.getBarChartDataObject(props.usage, props.target, props.lastYear, props.monthLabels, 'water');
    // hiding last year for now

    const usage = props.usage;
    const max = usage.reduce(function(a, b) {
        return Math.max( a, b);
    });

    const target = props.target;
    const lastYear = []; // Array(13).fill(300); //  props.lastYear;
    const extrapolatedUsage = props.extrapolatedUsage;

    const data = ChartUtils.getBarChartDataObject(usage, target, lastYear, extrapolatedUsage, props.monthLabels, 'water');

    options.scales.yAxes[0].ticks.maxTicksLimit = 8;
    options.scales.yAxes[0].ticks.suggestedMax = max;

return <>
        <h2>{ props.title }</h2>
        <UseChart 
            className="water"
            title={ props.subTitle }
            overview={ props.overview }
            color="blue">

            <BarChart
                period="Annual"
                average={ <span><em>{props.monthlyAverage}</em> gal monthly average</span>}
                total={ <span><em>{props.annualTotal}</em> gal annual total</span>}
                data={ data }
                options={ options }
                usageStatus={ props.usageStatus }
                
                extrapolatedValueForPeriod={ props.extrapolatedMonthlyTotal }
                targetValueForPeriod={ props.targetValueMonthly }
                />
        </UseChart>
    </>
}

export default WaterUse;