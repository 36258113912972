import React from 'react';

const UseChart = (props) => {

    const getColorClass = () => {
        return props.color ? `gradient gradient--${props.color}` : '';
    }

    const getChartClass = () => {
        let className = 'use-chart';
        if( props.className ) className += ' use-chart--' + props.className;

        return className;
    }

    return <section className={ getChartClass() }>
            <div className="use-chart__overview">
                <h3>{ props.title }</h3>
                <p>{ props.overview }</p>
            </div>

            <div className="use-chart__chart">
                <div className={ getColorClass() }>
                    
                    { props.children }
                    
                </div>
            </div>
        </section>
}

export default UseChart;